import {contentfulQueryKeys} from '../../../constants'
import {getGlobalSettingsQuery} from '../queries/global-settings.query'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useGlobalSettingsContentful = () => {
    const {data, error, isLoading} = useContentfulQueryWithHook(
        contentfulQueryKeys.getGlobalSettingsQuery,
        getGlobalSettingsQuery
    )

    return data
}
